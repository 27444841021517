<template>
  <v-container fluid tag="section">
    <v-card>
      <v-card-title>Assessment Summary</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="h_summary"
          :items="assessmentSummary"
          :items-per-page="5"
          class="elevation-0"
          page
        ></v-data-table>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="pretestAssessment.program.name"
                  label="Program"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="pretestAssessment.school.name"
                  label="School"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="pretestAssessment.assessment_tool"
                  label="Assessment Tool"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="PreTest Assessment Date"
                  v-model="pretestAssessment._date"
                  readonly
                ></v-text-field
              ></v-col>
              <v-col cols="3">
                <v-text-field
                  label="Post Assessment Date"
                  v-model="assessmentStatus.created_at"
                  readonly
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="pretestLearners"
              :items-per-page="5"
              class="elevation-0"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Learners</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn to="/assessmentlist" tile>
              <v-icon>mdi-arrow-left-top</v-icon>Back to List</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { sync, dispatch } from "vuex-pathify";

export default {
  // name: "AssessmentSummary",
  computed: {
    ...sync("assessment", [
      "el",
      "pretestAssessment",
      "pretestLearners",
      "assessmentStatus",
      "assessmentSummary",
    ]),
  },
  data() {
    return {
      h_summary: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "level",
        },
        { text: "Pre Test", value: "pretest" },
        { text: "Post Test", value: "posttest" },
      ],
      headers: [
        { text: "Last Name", value: "learner.surname" },
        { text: "First Name", value: "learner.gname" },
        { text: "Grade Level", value: "gradeLvl" },
        { text: "Type", value: "type" },
        { text: "PreTest Value", value: "_value" },
        { text: "PreTest Level", value: "_level" },
        { text: "PostTest Value", value: "post_value" },
        { text: "PostTest Level", value: "post_level" },
      ],
      summary:{
        'level':'',
        'pretest':'',
        'posttest':'',
      }
    };
  },

  onMounted() {
    this.loadSummary();
  },

  methods: {
    async loadSummary() {
      console.log("tests")
      await dispatch("assessment/getassessmentSummary");
    },
  },
};
</script>